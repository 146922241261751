.nav_card {
  border: 0.125vmin solid #e6e6e6;
  border-radius: 2rem;
  transition: background-color 0.2s ease-in-out;
}

.nav_card:hover {
  background-color: var(--primary-color);
  border: 2px solid #f1652f;
}

.nav_card a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.nav_card_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.nav_card_name {
  text-align: center;
  color: #f1652f;
  font-size: 21.598px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.nav_card:hover .nav_card_name {
  color: #dbdfea;
}

.nav_card svg {
  height: 7.5vmin;
  width: 7.5vmin;
}

/* react ri icons */
.nav_card svg > g > path:nth-child(2) {
  fill: var(--gray-6);
}
.nav_card:hover svg > g > path:nth-child(2) {
  fill: var(--primary-color);
}

/* react fi icons */
.nav_card svg > * {
  fill: var(--gray-6);
}
.nav_card:hover svg > * {
  fill: #f1652f;
}
.nav_card_container h1 {
  font-size: 5rem;
  font-weight: 800;
  color: #a6b6c4;
  line-height: 3rem;
  padding-bottom: 2rem;
}
.nav_card:hover h1 {
  color: var(--primary-color);
}
.project_nav_card {
  position: relative;
}
