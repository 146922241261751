/* .nav_wrapper {
  padding-left: 5rem;
} */
.sidebar_nav {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}


.sidebar_nav > *:nth-child(2){
  position: relative;
  padding-top: 5rem;
  margin-left: 2rem;
}

.sidebar_nav > *:nth-child(2)::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #1D1E2C;;
}

.d_items {
  margin-left: 3rem;
  padding-bottom: 1.5rem;
}

.sidebar_nav {
  height: 77.5vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.sidebar_nav > *:nth-child(1) {
  /* height: 53vh; */
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.sidebar_nav > *:nth-child(1)::-webkit-scrollbar {
  display: none;
}

.sidebar_nav button {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.7);
  border-radius: 0.8rem;
  padding: 0.8rem 1.5rem;
}
.sidebar_nav .log_out:hover {
    background: rgba(241, 101, 47, 0.8);
}

.sidebar_nav button.active {
  background-color: var(--primary-color);
  color: var(--gray-7);
}

.sidebar_nav button svg {
  width: 2.4rem;
  height: 2.4rem;
}

.sidebar_nav button > span {
  padding-left: 2.4rem;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
