.dropDownItems > div:nth-child(1) {
  padding: 0.8rem 1.5rem;
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem;
}

.dropDownItems {
  margin-bottom: 1.86rem;
  cursor: pointer;
}
.dropDownItems:hover {
  background: rgba(241, 101, 47, 0.8);
}

.dropDownItems .item_title {
  cursor: pointer;
  display: flex;
  align-items: center;
}
.active_title {
  background: rgba(241, 101, 47, 0.8);
}
.dropDownItems .item_title p {
  color: #dbdfea;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding-left: 2.4rem;
}
.dropDownItems .item_title svg {
  color: #dbdfea;
  width: 2.4rem;
  height: 2.4rem;
}
.dropdownIcon svg {
  color: #dbdfea;
  width: 2rem;
  height: 2rem;
}
