.checkbox_large {
    transform: scale(1.5);
    margin-right: 10px;
  }
  
.submit_button{
    font-size: 20px;
    color: white;
    margin-bottom: 10px;
    margin-top: 20px;
    border: 1px solid #f1652f;
    padding: 10px 20px;
    float: right;
}

.submit_button:hover{
    background-color: #f1652f;
}
  