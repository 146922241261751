@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap");

:root {
  --primary-color: #24263a;
  --secodary-color: #1d1e2c;
  --white-2: #dbdfea;
}
body {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1.3rem;
  line-height: 2.4rem;

}
.searchLine:hover svg > * {
  fill: #f1652f;
}
.main {
  width                : 100vw;
  height               : 100vh;
  display              : grid;
  background-color: var(--secodary-color);
  overflow             : hidden;
}

.main.employee {
  grid-template-columns: 20% 1fr;
}

.main.auth {
  place-items: center;
}

.w_100 {
  width: 100%;
}

@media (orientation: portrait) {
  .main {
    display: block;
  }
}
