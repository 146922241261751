.sidebar_nav .item:not(:last-child)  {
  margin-bottom: 1.86rem;
}

.sidebar_nav a {
  display: flex;
  align-items: center;
  color: var(--white-2);
  border-radius: 0.8rem;
  padding: 0.8rem 1.5rem;
}

.sidebar_nav  a.active {
  border-radius: 66.667px 0px 0px 66.667px;
  background: #1D1E2C;
}

.sidebar_nav a svg {
  width: 2.4rem;
  height: 2.4rem;
}


.sidebar_nav a > span {
  padding-left: 2.4rem;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.sidebar_nav a.active svg{
  color: #F1652F;
}

.sidebar_nav a.active span{
  color: #F1652F;
}

.sidebar_nav a:hover{
  color: #F1652F;
}

