.forgot_pass p {
  color: #999;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  padding-bottom: 4rem;
}
.forgot_pass h3{
  padding-bottom: 1.5rem;
}
.forgot_pass .have_account{
    border-top: none;
}
.forgot_pass form{
  width: 100%;
}
.auth_status {
  color: #999;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  padding-bottom: 4rem;
}

.body_text{
  color: #ece8e8;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 2.1rem;
  padding-bottom: 4rem;
}