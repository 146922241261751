.screen_header {
  background-color: var(--primary-color);
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 1.1rem 1rem;
}
.screen_heading {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--white-2);
}

@media (orientation: portrait) {
  .screen_header {
    padding: 1.2rem 1rem;
  }
}
