.form_wrapper {
  max-width: 48.6rem;
  /* width        : 100%; */
  display: flex;
  /* height       : 100vh; */
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 0;
  padding: 4rem 4.2rem;
  border-radius: 10px;
  background-color: var(--primary-color);
  border: 1px solid #f1652f;
}

.form_wrapper h3 {
  color: #e6e6e6;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  line-height: 4rem;
  text-transform: uppercase;
  padding-bottom: 4rem;
}
