.btn_create {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.btn_create svg {
  width: 3rem;
  height: 3rem;
  color: var(--white-2);
}

.btn_create span {
  font-size: 2rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--white-2);
  margin-top: -0.4rem;
}

@media (orientation: portrait) {
  .btn_create span {
    display: none;
  }
  .btn_create svg {
    width: 2.4rem;
    height: 2.4rem;
  }
}
