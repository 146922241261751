/* General Styles */
body {
    margin: 0;
    font-family: Arial, sans-serif;
    background-color: #f9f9f9;
    color: #333;
    overflow-y: scroll; 
  }
  
  .product-label-printer {
    padding: 20px;
    max-width: 1200px;
    margin: auto;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  /* Search Bar */
  .search-container input {
    width: 100%;
    padding: 12px;
    margin-top: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
  }
  
  /* Product and Size Lists */
  .products-list,
  .sizes-list {
    margin-bottom: 20px;
  }
  
  .product-item,
  .size-item {
    display: inline-block; /* Changed to inline-block for horizontal layout */
    margin: 5px;
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    background-color: #f1f1f1;
  }
  
  .product-item.selected,
  .size-item.selected {
    background-color: #007bff;
    color: white;
    border-color: #007bff;
  }
  
  .sizes-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Added gap for spacing between items */
  }
  
  /* Label Quantity */
  .label-quantity label {
    font-size: 14px;
    margin-right: 10px;
  }
  
  .label-quantity input {
    padding: 5px;
    width: 80px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Buttons */
  .generate-button,
  .print-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    font-size: 14px;
    margin-top: 10px;
    transition: background-color 0.3s;
  }
  
  .generate-button:hover,
  .print-button:hover {
    background-color: #0056b3;
  }
  
  /* Labels Preview */
  .labels-preview {
    margin-top: 20px;
  }
  
  .labels-container {
    display: flex;
    gap: 20px;
    width: 50%;
  }
  
  .label {
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    page-break-after: always;
    background-color: #fff;
    border-radius: 4px;
    color: #000;
  }
  
  .label h2 {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .label p {
    margin: 5px 0 15px; 
    font-size: 14px;
    font-weight: bold;
  }
  
  .barcode {
    margin: 20px 0 0; /* Increased top margin for clarity */
  }
  
  /* Scrollbar Styles */
  ::-webkit-scrollbar {
    width: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 4px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
  /* Print Styles */
  @media print {
    body {
      margin: 0;
      padding: 0;
      background: white;
    }
  
    .product-label-printer {
      display: none;
    }
  
    .labels-container .label {
      width: 100%;
      height: 100%;
      font-size: 1.2rem;
    }
  }
  