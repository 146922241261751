.form_wrapper .upload_file_wrapper {
  display: block;
  margin-bottom: 2.4rem;
}
.have_account {
  margin-top: 6rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: .8rem;
  border-top: 1px solid #e6e6e6;
  padding-top: 1.5rem;


}
.have_account p {
  color: var(--gray-4);
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 3.2rem;
  text-align: center;
}
.have_account button {
  color: #e6e6e6;
  font-size: 1.7rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5rem;
  text-transform: capitalize;
}
