@media print{
@page {size: A4;margin : 0.3in 0in 0.3in 0.5in; /*padding:0px;*/ color:#000000;
}	
.modal-body{font-size: 11px !important; margin-top: 0px !important; padding-top: 0px !important;}
.modal-body .tabletitle{font-size: 11px !important;}
.modal-body .reportbill{font-size: 12px !important;}
.modal-body .mtable thead tr th{font-size: 10px !important; padding:5px 0px; background-color:#f5f5f5 !important;}
.panel-transparent{display: none;}
.copyright{display: none;}
.modal-header{display: none !important;}
.modal-content{/*height: 100% !important;*/ border: 0px !important; height:20000px !important;}
.modal-dialog{width: 100% !important;}
strong {font-size: 14px; font-weight:bold;}
footer {page-break-after: always;}
}

.print_btn {
    background: #eee none repeat scroll 0 0;
    border-radius: 5px;
    box-shadow: 0 0 5px #ccc;
    color: #090;
    cursor: pointer;
    display: inline-block;
    padding: 5px 10px;
    position: fixed;
    right: 20px;
    top: 20px;
}
.invoice_padding{
	margin-top:50px;
}