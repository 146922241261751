.modal_header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 4rem;
}

.modal_header > *:nth-child(1) {
  flex: 0 0 90%;
  width: 90%;
}

.modal_heading {
  font-size: 2rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--white-2);
}

.modal_close {
  font-size: 2.4rem;
  color: var(--gray-4);
}
