.forgot_password{
    padding-top: 2.6rem;
}
.forgot_password p {
  color: #e6e6e6;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.5ren; /* 156.25% */
}
