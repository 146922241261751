.dropdown-menu input {
    outline: none;
}

.dropdown-menu div:hover {
    background: #f1652f;
}

.dropdown-toggle:focus {
    border-color: #007bff;
    outline: none;
}
