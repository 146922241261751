.sidebar {
    height: 100vh;
    overflow-y: scroll;
}

::-webkit-scrollbar {
    display: none;
}

.sidebar_inner {
    height          : 100%;
    background-color: var(--primary-color);
    /* display         : flex;
    flex-direction  : column;
    justify-content : space-between; */
}

.sidebar .btn_close {
    position: absolute;
    top: 5rem;
    right: 3.2rem;
    display: none;
}

.sidebar .logo_wrapper {
    text-align: center;
    width: 200px;
    border-bottom : 1.5px solid #1D1E2C;
    padding-bottom: 3.5rem;
}

/* .sidebar.expanded .logo_wrapper .logo,
.sidebar:not(.expanded) .logo_wrapper .logo_wide {
    display: none;
} */

.logo_menu_wrapper {
    position: relative;
    padding: 4.8rem 2.4rem 3rem 4.9rem;
}

/* nav */
/* .sidebar .nav_wrapper {
    padding-top: 3rem;
} */


/* user logo */
.user_wrapper {
    background: rgba(255, 255, 255, 0.05);
    box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.06);
    padding   : 3rem 2rem;
}

.user_logout {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
    gap            : 1.2rem;
}

.user {
    display    : flex;
    align-items: center;
    gap        : 1.2rem;
}

.user .thumb {
    width : 4.2rem;
    height: 4.2rem;
}

.user img {
    width          : 100%;
    height         : 100%;
    object-fit     : cover;
    object-position: center center;
}

.user h6,
.user span {
    font-size  : 1.6rem;
    line-height: 2.0rem;
}

.user h6 {
    font-weight: 700;
    color      : var(--gray-1);
}

.user span {
    font-weight: 400;
    color      : var(--gray-7);
    margin-top : .2rem;
}


@media (orientation: portrait) {
    .sidebar {
        width    : 100%;
        position : fixed;
        top: 0;
        transform: translateX(-100%);
        transition: transform .3s ease-in-out;
        z-index: 2;
    }

    .sidebar.show {
        transform: translateX(0);
    }
    .sidebar .btn_close {
        display: block;
    }
    
    .user_wrapper {
        display: none;
    }

    .logo_menu_wrapper {
        height: 100%;
    }
}