.data_table_wrapper {
  padding-top: 2.5rem;
}

.data_table_inner {
  width: 100%;
  height: 100%;
  background-color: var(--primary-color);
  border-radius: 1rem;
  padding: 2.3rem 3.4rem 3.4rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* data table */
.data_table {
  width: 100%;
  height: 85%;
  border-collapse: separate;
  /* border-spacing : 0 1.5rem; */
  table-layout: fixed;
}

.data_table tbody {
  height: 100%;
  display: block;
  overflow-y: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.data_table tbody::-webkit-scrollbar {
  display: none;
}

.data_table thead,
.data_table tbody tr {
  display: table;
  width: 100%;
  border-collapse: separate;
  table-layout: fixed;
}

.data_table tbody tr {
  border-spacing: 0 0.8rem;
}

.data_table tbody tr td {
  background-color: var(--secodary-color);
}

.data_table th,
.data_table td {
  padding-left: 2.6rem;
  padding-right: 2.6rem;
}

.data_table th:not(:last-child),
.data_table td:not(:last-child) {
  text-align: left;
}

.data_table th:last-child,
.data_table td:last-child {
  text-align: right;
}

.data_table th {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  color: var(--white-2);
  padding-bottom: 2rem;
}

.data_table tbody td:first-child {
  border-top-left-radius: 1rem;
  border-bottom-left-radius: 1rem;
}

.data_table tbody td:last-child {
  border-top-right-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

/* .data_table tbody>tr:not(:last-child)>td {
    border-bottom: 1.5rem solid var(--black); 
} */

.data_table td {
  color: var(--white, #fff);
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  padding-top: 2.4rem;
  padding-bottom: 2.4rem;
  margin-bottom: 1.5rem;
}

.data_table td > img {
  max-width: 6rem;
}

.action_buttons_wrapper {
  position: relative;
}

.three_dots {
  display: none;
}

.action_buttons {
  display: flex;
  justify-content: flex-end;
}

.action_buttons button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4.4rem;
  height: 4.4rem;
  color: var(--white-2);
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
}

.action_buttons button:not(:last-child) {
  margin-right: 1.5rem;
}

.action_buttons button.trash {
  color: #ff0000;
}

.action_buttons button:hover {
  background-color: var(--primary-color);
}

.action_buttons button:not(.trash):hover {
  color: var(--white);
}

@media (orientation: portrait) {
  .data_table_inner {
    padding: 0.5rem 1.5rem 2.5rem;
  }

  .data_table {
    border-spacing: 0 1rem;
  }

  .data_table th,
  .data_table td {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }

  .data_table th {
    font-size: 1rem;
    line-height: 1.3rem;
    padding-bottom: 0.5rem;
  }

  .data_table td {
    font-size: 1.2rem;
    line-height: 1.5rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .data_table td > img {
    max-width: 4rem;
  }

  .action_buttons {
    position: absolute;
    top: calc(100% + 1rem);
    right: 0;
    background-color: var(--black);
    border-radius: 0.8rem;
    padding: 1rem 1.4rem;
  }

  .action_buttons::after {
    content: "";
    position: absolute;
    top: -0.7rem;
    right: 0.5rem;
    width: 0;
    height: 0;
    border-left: 1rem solid transparent;
    border-right: 1rem solid transparent;
    border-bottom: 12px solid var(--black);
  }

  .three_dots {
    display: inline-block;
  }

  .action_buttons button {
    width: 3rem;
    height: 3rem;
    background-color: rgba(26, 26, 26, 0.9);
  }

  .action_buttons button > svg {
    width: 1.6rem;
    height: 1.6rem;
  }
}
