.collection-menu {
    width: 100%;
}

.collection-menu .dropbtn {
    color: var(--gray-3);
    padding: 14px 16px;
    text-decoration: none;
    background-color: var(--secodary-color);
    width: 100%;
    border-radius: 5px;
    text-align: left;
    border: 1px solid transparent;
    margin-bottom: 7px;
    text-transform: capitalize;
    
}
.collection-menu .dropbtn:hover{
    border: 1px solid var(--gray-4);
}
.collection-menu .dropdownTitle{
    color: #fff;
    margin-bottom: 10px;
}

.dropdown {
    position: relative;
    display: block;
}

.dropdown-content {
    position: absolute;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: var(--secodary-color);
}

.dropdown-content button {
    color: #fff;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
    width: 100%;
    
    margin-bottom: 3px;
}

.dropdown-content button:hover{
    color: #fff;
    background-color: var(--primary-color);
}
.dropdown-content button.selected {
    color: #fff;
    background-color: rgba(241, 101, 47, 0.8);
}

.dropdown:hover .dropdown-content {
    display: block;
}